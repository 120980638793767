import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.user) {
      const authHeaderValue = `Bearer ${this.authService.user.idToken}`;
      const authRequest = request.clone({
        headers: request.headers.set('Authorization', authHeaderValue)
      });
      return next.handle(authRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 && this.authService.user) {
            this.authService.signOut();
          }

          return throwError(error);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
