import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private socialUser: SocialUser = null;

  constructor(private socialAuthService: SocialAuthService, private router: Router) {
    this.socialAuthService.authState.subscribe(user => {
      this.socialUser = user;
      if (!user) {
        this.router.navigateByUrl('/login');
      }
    });
  }

  public get isAuthenticated(): boolean {
    return !!this.socialUser;
  }

  public get user(): SocialUser {
    return this.socialUser;
  }

  public get authState(): Observable<SocialUser> {
    return this.socialAuthService.authState;
  }

  public signOut(): void {
    this.socialAuthService.signOut();
  }
}
