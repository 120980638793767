import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { RosettaService, RosettaStatus } from './rosetta.service';
import { ResponsiveService } from './responsive.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValueSetFileService } from './value-set-file.service';
import { SocialUser } from 'angularx-social-login';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'value-set-editor';

  public rosettaStatus$: Observable<string>;
  public user: SocialUser;

  constructor(
    private rosetta: RosettaService,
    private valueSetFileService: ValueSetFileService,
    private authService: AuthService,
    private responsive: ResponsiveService) {
    this.rosettaStatus$ = this.rosetta.status$.pipe(
      map(status => RosettaStatus[status])
    );
    this.authService.authState.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.rosetta.getCodeSystems();
      }
    });
  }

  ngOnInit(): void {
    this.rosetta.startPeriodicReadyCheck();
    this.valueSetFileService.init();
  }

  ngAfterViewInit(): void {
    this.responsive.setWindowHeight(window.innerHeight);
  }

  signOut(): void {
    this.authService.signOut();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.responsive.setWindowHeight(event.target.innerHeight);
  }
}
