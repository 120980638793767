import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroy$ = new ReplaySubject<boolean>(1);
  public initialized = false;

  constructor(private router: Router, private socialAuthService: SocialAuthService) { }

  ngOnInit(): void {
    this.socialAuthService.initState.pipe(
      takeUntil(this.destroy$)
    ).subscribe(initialized => {
      this.initialized = initialized;
    });

    this.socialAuthService.authState.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.router.navigateByUrl('/app/home');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
}
