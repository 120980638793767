<mat-toolbar color="primary" role="heading" [routerLink]="['app','home']">
  <span>Rosetta ValueSet Editor</span>
  <span class="toolbar-spacer"></span>
  <span class="api-status">Rosetta: {{rosettaStatus$ | async}}</span>
  <span *ngIf="user">
    <div class="avatar">
      <img src="{{ user.photoUrl }}">
    </div>
    {{user.name}}
    <button mat-raised-button (click)="signOut()">Log out</button>
  </span>
</mat-toolbar>

<div class="content" role="main">
  <router-outlet></router-outlet>
</div>
