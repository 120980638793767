import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject, interval, from, range, Subject } from 'rxjs';
import { bufferCount, flatMap, map, reduce, toArray, withLatestFrom } from 'rxjs/operators';
import { CodeSystem } from './model/code-system';
import { Coding } from './model/coding';
import { CodingPage } from './model/coding-page';
import { CsvFolder } from './model/csv-folder';
import { ValueSetFile, ValueSetFileOps } from './model/value-set-file';
import { ValueSetOps } from './model/value-set';
import { ConfigService } from './config.service';

export enum RosettaStatus {
  Ready,
  Starting,
  NotResponding
}

const SCOPE_URL = 'https://rosetta.careevolution.com/api/FHIR/STU3/ValueSet/Extension/Scope';
const DOMAIN_URL = 'https://rosetta.careevolution.com/api/FHIR/STU3/translate-dependency/domain';

function getStringExtension(resource: any, url: string): string {
  return resource.extension?.find((ext: any) => ext.url === url)?.valueString;
}

function getIntExtension(resource: any, url: string): number {
  return resource.extension.find((ext: any) => ext.url === url).valueInteger;
}

function getBooleanExtension(resource: any, url: string): boolean {
  if (!resource.extension) {
    return false;
  }

  const extension = resource.extension.find((ext: any) => ext.url === url);
  if (!extension) {
    return false;
  }
  return extension.valueBoolean;
}

export interface LookupResult {
  sourceCoding: Coding;
  validatedCoding: Coding;
  error: string;
  found: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RosettaService {
  private statusSubject = new ReplaySubject<RosettaStatus>(1);
  public readonly status$ = this.statusSubject.asObservable();

  private codeSystemsSubject = new ReplaySubject<CodeSystem[]>(1);
  public readonly codeSystems$ = this.codeSystemsSubject.asObservable();

  private progressSubject = new Subject<number>();
  public readonly progress$ = this.progressSubject.asObservable();

  constructor(private http: HttpClient, private config: ConfigService) {
  }

  startPeriodicReadyCheck() {
    interval(5000).subscribe(_ => {
      this.http.get(this.buildUrl('/ready'), { responseType: 'text' })
        .subscribe(__ => { /* empty - the ready interceptor will handle the responses */ });
    });
  }

  getCsvFileList(): Observable<CsvFolder[]> {
    return this.http.get(this.buildUrl('/api/FHIR/STU3/ValueSet/Rosetta.ValueSetCsvFiles')).pipe(
      map((valueSet: any) => {
        const folders = valueSet.compose.include.map((inc: any) => new CsvFolder(inc.system, inc.concept.map((c: any) => c.code)));
        return folders;
      })
    );
  }

  getCodeSystems(): Observable<CodeSystem[]> {
    const request = this.http.get(this.buildUrl('/api/FHIR/STU3/CodeSystem?_summary=true')).pipe(
      map((bundle: any) => {
        const valueSets = bundle.entry.map((entry: any) => new CodeSystem(
          entry.resource.id,
          entry.resource.name,
          entry.resource.url,
          entry.resource.count,
          getBooleanExtension(entry.resource, 'sort-as-string'),
          getBooleanExtension(entry.resource, 'has-domain')));
        return valueSets;
      })
    );

    request.subscribe(this.codeSystemsSubject);

    return request;
  }

  getValueSetFile(folder: string, fileName: string): Observable<ValueSetFile> {
    const params = new HttpParams()
      .append('extension.source', folder)
      .append('extension.filename', fileName)
      .append('page.num', '0')
      .append('_count', '10000');

    const valueSetsRequest = this.http.get(this.buildUrl('/api/FHIR/STU3/ValueSet'), {
      params
    });

    return valueSetsRequest.pipe(
      withLatestFrom(this.codeSystems$, (bundle: any, codeSystems) => {
        const pkg = new ValueSetFile();
        pkg.fileName = fileName;
        pkg.folderName = folder;

        bundle.entry.map((entry: any) => entry.resource).forEach((resource: any) => {
          const name = resource.name;
          const scope = getStringExtension(resource, SCOPE_URL);
          const valueSet = ValueSetFileOps.getOrAdd(pkg, scope, name);

          resource.compose.include.forEach((inc: any) => {
            const systemId = codeSystems.find(cs => cs.url === inc.system).id;

            inc.concept.forEach((concept: any) => {
              const coding = new Coding(
                systemId,
                concept.code,
                concept.display,
                getStringExtension(concept, DOMAIN_URL));

              ValueSetOps.add(valueSet, coding);
            });
          });

          ValueSetOps.sort(valueSet, codeSystems);
        });

        return pkg;
      }));
  }

  searchForCodesAndDisplays(
    systemId: string,
    codeSearch: string,
    displaySearch: string,
    pageNum: number,
    pageSize: number): Observable<CodingPage> {
    const url = this.buildUrl(`/api/FHIR/STU3/CodeSystem/${systemId}`);
    let params = new HttpParams()
      .append('page.num', (pageNum - 1).toString())
      .append('_count', pageSize.toString());

    if (codeSearch && codeSearch !== '') {
      params = params.append('concept.code', codeSearch);
    }
    if (displaySearch && displaySearch !== '') {
      params = params.append('concept.display', displaySearch);
    }
    return this.http.get(url, { params }).pipe(
      withLatestFrom(this.codeSystems$),
      map(([fhirCodeSystem, codeSystems]: [any, CodeSystem[]]) => {
        return this.processSearchPage(fhirCodeSystem, codeSystems, pageNum, pageSize);
      })
    );
  }

  searchForCodes(systemId: string, search: string, pageNum: number, pageSize: number): Observable<CodingPage> {
    const url = this.buildUrl(`/api/FHIR/STU3/CodeSystem/${systemId}`);
    let params = new HttpParams()
      .append('page.num', (pageNum - 1).toString())
      .append('_count', pageSize.toString());

    if (search && search !== '') {
      params = params.append('concept:contains', search);
    }
    return this.http.get(url, { params }).pipe(
      withLatestFrom(this.codeSystems$),
      map(([fhirCodeSystem, codeSystems]: [any, CodeSystem[]]) => {
        return this.processSearchPage(fhirCodeSystem, codeSystems, pageNum, pageSize);
      })
    );
  }

  private processSearchPage(fhirCodeSystem: any, codeSystems: CodeSystem[], pageNum: number, pageSize: number): CodingPage {
    const codeSystemsByUrl = new Map<string, CodeSystem>();
    codeSystems.forEach(cs => codeSystemsByUrl.set(cs.url, cs));

    const searchTotal = getIntExtension(fhirCodeSystem, 'search-total');

    if (fhirCodeSystem.concept) {
      const codeSystem = codeSystemsByUrl.get(fhirCodeSystem.url);
      const setDomain = codeSystem.id.startsWith('FhirCode') || codeSystem.id === 'CareEvolution';

      const codings = fhirCodeSystem.concept.map((c: any) => new Coding(
        codeSystem.id,
        c.code,
        c.display,
        setDomain ? getStringExtension(c, DOMAIN_URL) : ''));
      return new CodingPage(codings, pageNum, pageSize, searchTotal);
    } else {
      return new CodingPage([], pageNum, pageSize, searchTotal);
    }
  }

  lookupCodings(codings: Coding[]): Observable<LookupResult[]> {
    const seed: LookupResult[] = [];
    let completedBatches = 0;
    const batchSize = 100;
    const batches = Math.ceil(codings.length / batchSize);
    this.progressSubject.next(0);
    return from(codings).pipe(
      bufferCount(batchSize),
      flatMap(batch => this.lookupBatch(batch), 10),
      reduce((acc, batch) => {
        completedBatches++;
        this.progressSubject.next(completedBatches * 100 / batches);
        return acc.concat(batch);
      }, seed)
    );
  }

  private lookupBatch(batch: Coding[]): Observable<LookupResult[]> {
    const url = this.buildUrl(`/api/FHIR/STU3`);
    const codeSystemIds = batch.map(c => c.systemId); // no easy way to get this out of the response object
    const bundle = {
      resourceType: 'Bundle',
      type: 'batch',
      entry: batch.map(c => {
        let query = new HttpParams();
        query = query.append('code', c.code);
        query = query.append('system', c.systemId);
        return {
          request: {
            method: 'GET',
            url: `CodeSystem/$lookup?${query}`
          }
        };
      })
    };

    return this.http.post(url, bundle).pipe(
      map((resp: any) => {
        const respCodings: LookupResult[] = resp.entry.map((respEntry: any, index: number) => {
          if (respEntry.response.status.startsWith('404')) {
            return {
              sourceCoding: batch[index],
              found: false,
              error: respEntry.resource.issue[0].diagnostics
            };
          }
          else if (respEntry.response.outcome) {
            return {
              sourceCoding: batch[index],
              validatedCoding: null,
              error: respEntry.response.outcome.issue[0].diagnostics
            };
          }
          else if (respEntry.resource.resourceType === 'Parameters') {
            const parameters = new Map<string, string>();

            for (const parameter of respEntry.resource.parameter) {
              parameters.set(parameter.name, parameter.valueString);
            }

            return {
              sourceCoding: batch[index],
              validatedCoding: new Coding(
                codeSystemIds[index],
                parameters.get('code'),
                parameters.get('display'),
                parameters.get('domain')),
              error: null,
              found: true,
            };
          }
          else if (respEntry.resource.resourceType === 'OperationOutcome') {
            return {
              sourceCoding: batch[index],
              validatedCoding: null,
              error: respEntry.resource.issue[0].diagnostics
            };
          }
          else {
            return {
              sourceCoding: batch[index],
              validatedCoding: null,
              error: `Unexpected resource type ${respEntry.resource.resourceType}`
            };
          }
        });
        return respCodings;
      }));
  }

  setRosettaStatus(status: RosettaStatus) {
    this.statusSubject.next(status);
  }

  private buildUrl(path: string) {
    return this.config.rosettaUrl + path;
  }
}
