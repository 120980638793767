import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RosettaService, RosettaStatus } from '../rosetta.service';
import { tap, finalize } from 'rxjs/operators';

@Injectable()
export class ReadyInterceptor implements HttpInterceptor {

  constructor(private rosetta: RosettaService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let status = RosettaStatus.NotResponding;
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            const httpResponse = event as HttpResponse<unknown>;
            status = this.statusFromHttpCode(httpResponse.status);
          } else {
            status = RosettaStatus.NotResponding;
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            const errorResponse = error as HttpErrorResponse;
            status = this.statusFromHttpCode(errorResponse.status);
          } else {
            status = RosettaStatus.NotResponding;
          }
        }),
      finalize(() => this.rosetta.setRosettaStatus(status))
    );
  }

  private statusFromHttpCode(httpStatus: number): RosettaStatus {
    if (httpStatus >= 200 && httpStatus < 300) {
      return RosettaStatus.Ready;
    } else if (httpStatus === 503) {
      return RosettaStatus.Starting;
    } else {
      return RosettaStatus.NotResponding;
    }
  }
}
